.therapist-showcase {

  .showcase-chart {
    img {
      width: 335px;
      height: 214px;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
    }

    .chart-area {
      background-color: #212324;
      padding: 20px;
      border-radius: 20px;
      height: 215px;
    }
  }

  .row {
    margin-bottom: 10px;
  }

  .badge {
    height: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
  }

  .send-message {
    cursor: pointer;
  }
}