@import "../../styles/base/index.scss";

.customModalTitle1 {
  color: $color6;
}

.custom-modal {
  min-width: 590px !important;

  & .modal-content {
    border-radius: 15px !important;
    border: none !important;
    padding: 32px 40px;

    & .modal-header {
      padding: 0 0 14px;
      border: none;

      & .modal-title {
        @include font1;
        @include defaultBoldFont;
      }
    }

    & .modal-body {
      padding: 17px 0;
    }

    & .modal-footer {
      border: none;
      padding: 15px 0 0;

      & .button {
        margin: 0;
      }
    }
  }
}
