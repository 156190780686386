@import "../../styles/base/index.scss";

.button {
  @include defaultBtn;
  @include defaultBoldFont;
  border: 1px solid transparent;
  height: 40px;

  &_type {
    &_default {
      background: $color4;
      color: white;
      border: none;

      &:focus,
      &:hover,
      &:active {
        background: $color4Focus;
      }

      &.button_type_disabled {
        color: white;
        cursor: default;
        background: $color1;

        &:focus,
        &:hover,
        &:active {
          color: white;
        }
      }
    }

    &_outline {
      border: 1px solid $color4;
      color: $color4;
      background: white;

      &:focus,
      &:hover,
      &:active {
        border: 1px solid $color4Focus;
        color: $color4Focus;
      }

      &.button_type_disabled {
        color: $color1;
        border: 1px solid $color1;
        cursor: default;

        &:focus,
        &:hover,
        &:active {
          color: $color1;
          border: 1px solid $color1;
        }
      }
    }

    &_simple {
      color: $color1;
      background: white;
      @include defaultFont;

      &:focus,
      &:hover,
      &:active {
        color: $color4Focus;
      }

      &.button_type_disabled {
        color: $color1;
        cursor: default;

        &:focus,
        &:hover,
        &:active {
          color: $color1;
        }
      }
    }
  }
}
