@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"), url("Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  font-style: normal;
  font-weight: bold;
  src: local("Poppins-Bold"), url("Poppins-Bold.ttf") format("truetype");
}
