@import "../styles/base/index.scss";
.hr {

  display: flex;
  border: 0;
  margin: 2em 0;
  width: 100%;
  
  .hr__line {
    display: flex;
    margin-top: 0.7em;
    border-top: 1px solid $color6;
    width: calc(45% - 1em);
  }

  .hr__content {
    display: flex;
    text-align: center;
    width: 10%;
    margin: 0 1em;
    align-items: center;
    justify-content: center;
  }
}