.upgrade-banner {
  margin: 0 45px 0 45px;
  --bs-alert-padding-x: 2rem;
  --bs-alert-padding-y: 0.6rem;
  --bs-alert-border: 0px solid var(--bs-alert-border-color);

  &.alert {
    padding-left: 36px;
    padding-top: 14px;
    padding-bottom: 10px;
  }
  
  &.alert-warning {
    background-color: #FF7A00;
    color: #fff;

  }
  
  .trial-message {
    padding-right: 4em;
  }
  
  .btn.upgrade-button {
    margin-top: -4px;
    background-color: #000;
    border-color: #000;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-weight: bold;
    line-height: 20px;
  }
}