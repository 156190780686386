.account-disabled {
  width: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    margin-top: 10%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
  }
}