@import "../../styles/base/index.scss";

.dropdown {
  @include defaultBoldFont;
  border: 0px solid transparent;
  height: 40px;

  &_type {
    &_default {
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 20px;
      background: $color4;
      color: white;
      border: none;

      &:focus,
      &:hover,
      &:active {
        background: $color4Focus;
      }
    }
  }

  .dropdown-menu {
    button.dropdown-item {
      @include font3;
      padding: 16px;

      &.delete {
        color: red;
      }
    }
  }
}
