@import "../../styles/base/index.scss";

.page-header {
  padding: 0 270px 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  box-sizing: content-box;
  gap: 20px;

  &__title {
    @include font1;
    @include defaultBoldFont;
    margin: 0;
    font-size: 45px;
    color: $color8;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
