.pexels-images {

  .image {
    border: 2px solid transparent;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 10px;
    text-align: center;

    img {
      margin: 10px auto;
    }

    &:hover {
      border: 2px solid #f0f0f0;

      &.selected {
         border: 2px solid green;
      }
    }

    &.selected {
      border: 2px solid lime;
    }
  }
}

.highlight {
  color: red;
  font-weight: bold;
}

.selectable-link {
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid lime;

    &::after {
      content: ' (click to select)';
      color: lime;
      text-decoration: none !important;
    }
  }

  &.remove-link {
    &:hover {
      border: 2px solid red;

      &::after {
        content: ' (click to remove)';
        color: red;
        text-decoration: none !important;
      }
    }
  }
}