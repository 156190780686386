.readiness-distribution {
  margin-top: 80px;
  .chart-area {
    width: 450px;
  }

  p {
    text-align: center;
    margin-top: 40px;
    margin-right: calc(100% - 450px);
  }
}