@import "./index.scss";

.table-list {
  font-size: 14px;

  input[type="checkbox"] {
    width: 24px;
    height: 24px;
  }

  tbody {
    tr:hover {
      th, td {
        background-color: $color7;
      } 
    }
  }

  th, td {
    padding-top: 9px;
    padding-bottom: 9px;
    line-height: 30px;
  }

  &.table > :not(:first-child) {
    border-top-color: $color5;
  }

  .checkbox {
    width: 24px;
  }

  .field {
    width: 135px;
  }

  th.sorting {
    background-color: $color2;
    width: 160px;
  }

  .no-border-right {
    border-right-color: transparent;
  }
}

