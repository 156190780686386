@import "../../../../styles/base/variables.scss";

.badge-form {
  .modal-body {
    .row {
      margin-bottom: 16px;
    }

    .repetitions-label {
      position: relative;
      span {
        position: absolute;
        bottom: 12px;
        color: $color1;
        font-size: 14px;
      }
    }

    .day-selection {
      font-size: 14px;
      label {
        margin-bottom: 0;
      }
    }

    .badge-images-selection {
      width: 240px;
      display: flex;
      flex-wrap: wrap;

      .badge-item {
        border: 1px solid $color1;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 4px;
        margin-right: 4px;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }
      .badge-item-selected {
        border: 2px solid red;
      }
    }
  }
}