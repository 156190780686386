.step1 {
  .hint {
    font-size: 12px;
    color: #444444;
  }

  .background-image {
    width: 100px;
    margin: 0 0 0 auto;
  }
}

.label-checkbox {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;

  .checkbox {
    margin: 0 10px 0 0;
  }

  .input-container {
    width: 1.5em;
  }
}