.soundscape {
  .group-item__container {
    .emoi {
      height: 100%;
      text-align: center;
      font-size: 72px;
      padding-top: 18px;
    }
  }

  .premium-badge {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    top: 8px;
  }
}