@import "../../../../styles/base/variables.scss";

.action-form {
  .modal-body {
    .row {
      margin-bottom: 16px;
    }

    .repetitions-label {
      position: relative;
      span {
        position: absolute;
        bottom: 12px;
        color: $color1;
        font-size: 14px;
      }
    }

    .day-selection {
      font-size: 14px;
      label {
        margin-bottom: 0;
      }
    }
  }
}