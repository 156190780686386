@import "../../styles/base/index.scss";

.admin-layout {
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% - 298px);
  }

  &__content {
    flex-grow: 1;
    margin: 25px 30px;
    border-radius: 5px;
    background: white;
    padding: 15px 15px 20px;
    position: relative;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 28px 45px 28px 30px;

  &__dropdown-container {
    & .header__dropdown {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      height: 42px;
      box-shadow: 0px 4px 22px 6px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      background: white;
      padding: 8px;
      @include font2;
      @include removeOutline;
    }

    & .dropdown-item {
      color: $color1;

      &:active {
        background: $color2;
        color: $color1;
      }
    }
  }
}

.logo {
  padding: 37px 25px 60px 0;

  &__img {
    width: 240px;
    height: 60px;
  }
}

.sidebar {
  background: white;
  width: 298px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 1;

  &__menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    &-item {
      &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 35px;
        padding: 15px 25px;
        @include sidebarMenuItemLink;

        &:focus,
        &:active,
        &:hover {
          @include sidebarMenuHoverItemLink;
        }

        & svg {
          width: 35px;
          height: 30px;
        }
      }

      &_active {
        & .sidebar__menu-item-link {
          @include sidebarMenuActiveItemLink;
        }
      }
    }
  }

  &__bottom {
    padding-bottom: 15px;

    &-item {
      &_active {
        & .sidebar__bottom-item-link {
          @include sidebarMenuActiveItemLink;
        }
      }

      &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 35px;
        padding: 15px 25px;
        @include sidebarMenuItemLink;

        &:focus,
        &:active,
        &:hover {
          @include sidebarMenuActiveItemLink;
        }

        & svg {
          width: 35px;
          height: 30px;
        }
      }
    }
  }
}
