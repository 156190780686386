@import "../../styles/base/variables.scss";

.action-tile {
  background-color: $color3;
  padding: 10px;
  position: relative;

  .days {
    width: 50px;
    position: absolute;
    top: 10px;
    left: calc(70px - 25px);
    img {
      margin-right: 1px;
    }
  }

  .action-repetitions, .action-title {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .action-repetitions {
    top: 47px;
    line-height: 50px;
    font-size: 24px;
  }

  .action-title {
    top: 93px;
    font-size: 17px;
    line-height: 20px;
  }

  .group-item__edit-btn {
    color: #444444;
  }

  .calendar-image {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .calendar-image {
    position: absolute;
    top: 10px;
    left: 10px;
  }

}