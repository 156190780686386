
.adoption {
  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #444444;
  }

  p { 
    font-size: 15px;
    line-height: 22px;
    color: #444444;
    text-align: left; 
    margin-left: 0; 
    margin-bottom: 0;
  }
}