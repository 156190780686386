
.radial-progress-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 446px;
  height: 376px;
  max-width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media all and (max-width: 600px) {
    zoom: 0.8;
    margin: 0 auto;
  }

  .background-gray-style {
    width: 440px;
    height: 376px;
    background: #f3f2e9;
    // background: red;
  }

  .image-1 {
    width: 446px;
    position: absolute;
    top: -34px;
    left: 0;
    z-index: 2;
  }

  h4 {
    position: absolute;
    font-weight: 700;
    font-size: 81px;
    line-height: 95px;
    margin: 0;
    letter-spacing: unset;
    color: #000000;
    bottom: 94px;
    z-index: 5;
  }

  .image-2 {
    position: absolute;
    z-index: 0;
    transform-origin: 50% 100%;
    width: 440px;

    top: 77px;
  }
}