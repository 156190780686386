@import "../../../styles/base/index.scss";

.sessions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
  }
}

.group {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
  }

  &__title {
    @include font5;
    @include defaultBoldFont;
    margin: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &_type {
      &_plus {
        padding: 10px;
        border-radius: 50%;
        background: $color4;
        width: 33px;
        height: 33px;
      }

      &_pencil {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__btn {
    border: none;
    padding: 0;
    background: none;
    @include removeOutline;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    white-space: nowrap;
  }
}

.group-item {
  min-width: 140px;
  max-width: 140px;
  display: inline-block;

  &__container {
    position: relative;
    height: 154px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 18px;
  }

  &__edit-btn, &__delete-btn {
    position: absolute;
    padding: 0;
    background: none;
    border: none;
    width: 28px;
    height: 28px;
  }

  &__edit-btn {
    right: 5px;
    top: 5px;
  }

  &__delete-btn {
    right: 5px;
    bottom: 5px;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__title {
    @include font3;
    @include defaultBoldFont;
    margin: 0;
    text-align: center;
    white-space: normal;
  }

  .publicity-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background-color: rgb(176, 6, 6);
    border-radius: 50%;

    &.published {
      background-color: rgb(0, 176, 6);
    }
  }
}

.session-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
