@import "../../styles/base/index.scss";

.guest-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
