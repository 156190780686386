.account-team {
  input {
    margin: 20px 0;
    width: 500px;
  }
}

.table-list {
  th {
    &.date {
      width: 20em;
    }

    &.mental-readiness {
      width: 60%;
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.qr-box {
  float: right;
  width: 300px;
  position: relative;

  .description {
    width: 50%;
    padding-top: 20px;
  }

  .download-qr {
    padding-top: 40px;
  }
}

.invite-qr {
  position: absolute;
  top: 0px;
  right: 0px;
}