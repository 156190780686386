.confirmation-button.btn-primary {
  background-color: #000;
  color: #fff;
  padding: 8px 30px;
  border-radius: 20px;

  &:hover {
    background-color: #000 !important;
  }

}
