.hero-measures {

  .radial-progress-wrapper {
    text-align: center;
  }
  .item {
    p {
      margin-top: -36px;
      margin-left: 160px;
    }

    margin-bottom: 50px;
  }

  .readiness-distribution {
    .showcase-chart {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    p { text-align: center; margin: 10px 0 20px 0; }
  }
}


.work-mental-readiness-change {
  
  padding-top: 100px;

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #444444;
  }

  p { 
    font-size: 15px;
    line-height: 22px;
    color: #444444;
    text-align: left; 
    margin-left: 0; 
    margin-bottom: 0;
  }

  .value {
    font-size: 48px;
    line-height: 72px;
    font-weight: 700;
  }
}