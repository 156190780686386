@import "../../../../styles/base/variables.scss";

.soundscape-form {
  .modal-body {
    .row {
      margin-bottom: 16px;
    }

    soundscape {
      margin-top: 20px;
    }

    .background-image {
      width: 100px;
      margin: 0 0 0 auto;
    }

    .hint {
      font-size: 12px;
      color: #444444;
    }

  }

  .id {
    font-size: 12px;
    color: #888888;
    text-align: right;
  }
}