@mixin removeOutline {
  outline: none !important;

  &:focus,
  &:active,
  &:hover {
    outline: none !important;
  }
}

@mixin defaultInput {
  @include font3;
  color: black;
  background-color: white;
  border: 1px solid $color5;
  box-sizing: border-box;
  border-radius: 3px;
  line-height: 17px;
  padding: 14px;
  @include removeOutline;

  &:active,
  &:focus {
    border: 1px solid $color4;
    box-shadow: none;
  }
}

@mixin defaultInputLabel {
  margin: 0 0 10px;
  display: block;
  @include font3;
}

@mixin defaultInputRequiredLabel {
  @include defaultInputLabel;

  &:after {
    content: "*";
    color: red;
  }
}

@mixin defaultBtn {
  @include font3;
  @include removeOutline;
  border-radius: 20px;
  padding: 0 30px;
  cursor: pointer;
}

@mixin sidebarMenuItemLink {
  text-decoration: none;
  @include font1;
  color: $color1;
  margin: 0 20px;
  border-radius: 10px;
}

@mixin sidebarMenuActiveItemLink {
  color: $color3;
  cursor: pointer;
  background: $color4;

  svg {
    filter: invert(100%)
  }
}

@mixin sidebarMenuHoverItemLink {
  color: $color2;
  cursor: pointer;
  margin: 0 20px;
  border-radius: 10px;
}
