.content-list {
  .content-title {
    cursor: pointer;
  }
}

.text-input {
  width: 100% !important;
}


.textarea-input {
  width: 100% !important;
}

.editable {
  cursor: pointer;
}

.row.breather {
  margin-top: 20px;
}

table {
  .created-at {
    min-width: 10em;
  }

  .model-name {
    font-size: 9px;
    min-width: 20em;
  }
}